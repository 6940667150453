import React, { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

export const track = (event: string) => {
  const track = window && (window as any).dataLayer
  if (typeof track === 'function') track.push({ event })
}

const pageview = () => {
  const pageview = window && (window as any).dataLayer
  if (typeof pageview === 'function') pageview.push({ event: 'pageview' })
}

export const AnalyticsProvider: React.FC<{ enabled?: boolean }> = ({
  enabled,
  children,
}) => {
  const { events } = useRouter()
  const trackingId = enabled ? process.env.NEXT_PUBLIC_GTM_ID : undefined
  const analyticsId = enabled ? process.env.NEXT_PUBLIC_GA_ID : undefined

  useEffect(() => {
    if (!trackingId) return

    events.on('routeChangeComplete', pageview)
    return () => {
      events.off('routeChangeComplete', pageview)
    }
  }, [trackingId, events])

  return (
    <React.Fragment>
      {trackingId && (
        <React.Fragment>
          <Head>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${trackingId}');`,
              }}
            />
          </Head>
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${trackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
          />
        </React.Fragment>
      )}
      {analyticsId && (
        <React.Fragment>
          <Head>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("set","allow_google_signals",!1),gtag("config","${analyticsId}",{anonymize_ip:!0});`,
              }}
            />
          </Head>
        </React.Fragment>
      )}
      {children}
    </React.Fragment>
  )
}
